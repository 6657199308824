function Stats() {
    return (
        <div className="container-fluid flex flex-col justify-center bg-gradient-to-r from-blue-600 via-blue-500 to-green-400 items-center gap-10 p-10">
            <h3 className="text-white font-bold text-2xl">Doint right by ALL our clients, ALL the time.</h3>
            <div className="flex flex-row gap-20 text-white">
                <section className="flex flex-col">
                    <p className="achievements">160+</p>
                    <p className="leading-8 tracking-wider">Projects Done</p>
                </section>
                <section>
                    <p className="achievements">145</p>
                    <p>Satisfied Clients</p>
                </section>
                <section>
                    <p className="achievements">3200</p>
                    <p>Cups of Coffee</p>
                </section>
                <section>
                    <p className="achievements">127,789</p>
                    <p>Lines of Code</p>
                </section>
            </div>
        </div>
    )
}

export default Stats;