import './App.css';
import About from './components/About';
import Home from './components/Home';
import Partners from './components/Partners';
import Services from './components/Services';
import Stats from './components/Stats';
import Why from './components/why';

function App() {
  return (
    <div className="App">
      <Home />
      <About />
      <Services />
      <Why />
      <Stats />
      <Partners />
    </div>
  );
}

export default App;
