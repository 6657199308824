import { FaGoogle } from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";

function Partners() {
    return (
        <div className="container-fluid min-h-screen flex flex-row justify-center items-center gap-10">
            <section className="flex flex-col items-start justify-center w-[600px] gap-4 text-[14px]">
                <h3 className="font-semibold bg-gradient-to-b from-green-400 to-blue-600 text-2xl bg-clip-text text-transparent border border-b-green-700">Partnering With The Best</h3>
                <p>Rankraze is one of India’s leading digital marketing company in Chennai, India. Our passionate team is working towards finding the Best Digital Marketing services for SME Business.</p>
                <section className="flex flex-row gap-10">
                    <FaGoogle className="text-3xl"/>
                    <FaMeta className="text-3xl text-blue-900"/>
                </section>
            </section>
            <section className="flex flex-col">
                <p className="bg-clip-text text-transparent border border-b-green-700"></p>
                <section></section>
                <section className="flex flex-row">
                    <p></p>
                    <p></p>
                    <p></p>
                </section>
            </section>
        </div>
    )
}

export default Partners;